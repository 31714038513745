import React from 'react'

const Footer = () => {

  return (
    <footer className="text-muted py-5 bg-sizzling-red" id="contact">
      <div className="container-fluid">
        <p className="float-end mb-1">
          <a href="#about" className="me-4 text-reset">
            <i className="fa fa-angle-double-up" aria-hidden="true"></i>
          </a>
        </p>
        <div className="d-flex justify-content-lg-start flex-column">
          {/* <h2 className="text-alice-blue mb-3 ms-2">Contact</h2> */}
          <div className="ms-2" id="sbtw">
            <div className="ms-2 d-flex flex-row">
              <a className="me-3 text-prussian-blue" href="mailto:cri.grande@gmail.com">
                <i className="far fa-envelope"></i>
              </a>
              <p className="text-prussian-blue"><a className="text-prussian-blue" href="mailto:cri.grande@gmail.com">cri.grande@gmail.com</a></p>
            </div>
            <div className="ms-2 d-flex flex-row">
              <a className="me-3 text-prussian-blue" href="https://www.linkedin.com/in/cristinagrandeit/" target="_blank" rel="noreferrer noopener">
                <i className="fab fa-linkedin"></i>
              </a>
              <a className="me-3 text-prussian-blue" href="https://www.linkedin.com/in/cristinagrandeit/" target="_blank" rel="noreferrer noopener">
                <p className="text-prussian-blue text-hover-white">linkedin/cristinagrandeit</p>
              </a>
            </div>
            <div className="ms-2 d-flex flex-row">
              <a className="me-3 text-prussian-blue" href="https://github.com/crigrande" target="_blank" rel="noreferrer noopener">
                <i className="fab fa-github"></i>
              </a>
              <a className="me-3 text-prussian-blue" href="https://github.com/crigrande" target="_blank" rel="noreferrer noopener">
                <p className="text-prussian-blue text-hover-white">github/crigrande</p>
              </a>
            </div>

          </div>
        </div>
        <div className="text-center p-4" styles="background-color: rgba(0, 0, 0, 0.05);">
          <p>© {new Date().getFullYear()} Created by
            <a className="text-prussian-blue fw-bold text-decoration-none" href="#">
              <span className="ms-1">Cristina Grande</span>
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}
export default Footer