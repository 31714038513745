import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import 'bootstrap'
import 'react-bootstrap'
import './styles/main.scss'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
