import React from 'react'
// import dj from '../../assets/img/dj.svg'
import Nav from './Nav'
import Footer from './Footer'
import TypeWriter from 'typewriter-effect'



const Home = () => {


  return (
    <>
      <Nav />
      <div className="container-fluid">
        <div className="row hero vertical-center text-center bg-pastel-yellow">
          <div className="col-sm-12">
            <img src="https://res.cloudinary.com/dbc3fejob/image/upload/v1629997681/Portfolio/LAST%20VERSION/Junior_Full-stack_developer_-_new_color_eqdgaa.png" className="img-fluid image text-sea-green text-hover" alt="CG" />
            <div className="text-sea-green text-decoration-none display-4">
              <a className="text-decoration-none text-sea-green text-sea-green-no-hover" href="#about">
                <TypeWriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString('Press here')
                      .pauseFor(2000)
                      .deleteAll()
                      .start()
                  }}
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        
        <div className="row mb-sm-5 mb-md-0 justify-content-center p-5 clip bg-primary hero text-alice-blue " id="about">
          <div className="col-sm-12 col-md-6 mt-5 ">
            <h1 className="mb-4">About me</h1>
            <div className="bg-shark">
              <div className="p-3">
                <p className="text-md">I am a <b>full-stack developer</b> based in London, with an immensive passion for technology.</p>
                <p className="text-md">I came from an <b>Economics</b> background but I soon found out that my passion was in tech. In my previous career I used to work with large amount of data and this made me explore new ways to manipulated the data in a more efficient manner.</p>
                <p className="text-md">In order to do this, I started studying <b>Python</b> and I soon realised that I was enjoying using my problem solving and logic skills to build the scripts.</p>
                <p className="text-md">I have then opted for a career change by joining the <b>General Assembly</b> bootcamp in <b>Software Engineering</b>.</p>
                <p className="text-md">During the course not only I learned different types of technologies, frameworks and tools but also how to approach real life
                scenarios to start a new journey as a <b>Developer</b>.</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-5">
            <h1 className="text-start mb-4">Skills</h1>
            <div className="row mb-5">
              <div className="col-sm-12 justify-content-around">
                <div className="row">
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-html5 icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-css3-alt icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-js-square icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-react icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-sass icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-bootstrap icon-big"></i>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 justify-content-around mt-0 mt-sm-0 mt-md-4">
                <div className="row">
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-python icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-node icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fas fa-database icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-github icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i className="fab fa-git-alt icon-big"></i>
                  </div>
                  <div className="col col-md-2 mt-3 mt-sm-2 mt-md-0">
                    <i>
                      <svg className="img-fluid img-svg" width="80" viewBox="0 0 256 326" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet"><g className="g-hover" fill="#F7FFF7"><path d="M114.784 0h53.278v244.191c-27.29 5.162-47.38 7.193-69.117 7.193C33.873 251.316 0 222.245 0 166.412c0-53.795 35.93-88.708 91.608-88.708 8.64 0 15.222.68 23.176 2.717V0zm1.867 124.427c-6.24-2.038-11.382-2.717-17.965-2.717-26.947 0-42.512 16.437-42.512 45.243 0 28.046 14.88 43.532 42.17 43.532 5.896 0 10.696-.332 18.307-1.351v-84.707z"/><path d="M255.187 84.26v122.263c0 42.105-3.154 62.353-12.411 79.81-8.64 16.783-20.022 27.366-43.541 39.055l-49.438-23.297c23.519-10.93 34.901-20.588 42.17-35.327 7.61-15.072 10.01-32.529 10.01-78.445V84.261h53.21zM196.608 0h53.278v54.135h-53.278V0z"/></g></svg>

                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-4 p-5 bg-sea-green" id="projects">
          <div className="col-sm-12">
            <h1 className="mb-4 text-alice-blue">Projects</h1>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card h-100">
              <img src="https://res.cloudinary.com/dbc3fejob/image/upload/v1630020859/Portfolio/Screenshot_2021-08-27_at_00.31.14_jryrex.png" className="card-img-top img-fluid img-thumbnail img-card" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">Connect 4 - Front-end game in Vanilla JavaScript</h5>
                <p className="card-text">Grid based game using vanilla JavaScript, HTML and CSS.</p>
                <span>
                  <a className="me-3 text-hover-reverse" href="https://crigrande.github.io/sei-project-1/" target="_blank" rel="noreferrer noopener">
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                  <a className="ms-1 me-3 text-hover-reverse" href="https://github.com/crigrande/sei-project-1" target="_blank" rel="noreferrer noopener">
                    <i className="fab fa-github"></i>
                  </a>
                </span>
                <ul className="tags mt-3 ">
                  <li><a className="tag cursor-default">JavaScript</a></li>
                  <li><a className="tag cursor-default">HTML</a></li>
                  <li><a className="tag cursor-default">CSS</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card h-100">
              <img src="https://res.cloudinary.com/dbc3fejob/image/upload/v1630020859/Portfolio/Screenshot_2021-08-27_at_00.32.00_rhlw3s.png" className="card-img-top img-fluid img-thumbnail img-card" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">The CockTailor - React app using external API + Game</h5>
                <p className="card-text">Web application with a public API integration from CocktailDB to build a cocktail menu and the information displayed in the cocktail game.</p>
                <span>
                  <a className="me-3 text-hover-reverse" href="https://60d5eb7ac17c0e4d93ac2804--thecocktailor.netlify.app/Cocktails" target="_blank" rel="noreferrer noopener">
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                  <a className="ms-1 me-3 text-hover-reverse" href="https://github.com/crigrande/sei-project-2" target="_blank" rel="noreferrer noopener">
                    <i className="fab fa-github"></i>
                  </a>
                </span>
                <ul className="tags mt-3">
                  <li><a className="tag special-effect cursor-default">JavaScript</a></li>
                  <li><a className="tag special-effect cursor-default">React</a></li>
                  <li><a className="tag special-effect cursor-default">HTML</a></li>
                  <li><a className="tag special-effect cursor-default">CSS</a></li>
                  <li><a className="tag special-effect cursor-default">SASS</a></li>
                  <li><a className="tag special-effect cursor-default">Bulma</a></li>
                  <li><a className="tag special-effect cursor-default">Insomnia</a></li>
                  <li><a className="tag special-effect cursor-default">Deployment - Netifly</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card h-100">
              <img src="https://res.cloudinary.com/dbc3fejob/image/upload/v1630020859/Portfolio/Screenshot_2021-08-27_at_00.32.51_fl33yz.png" className="card-img-top img-fluid img-thumbnail img-card" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">CEST - MERN application</h5>
                <p className="card-text">Full-Stack web application to rent flexible office spaces. Multiple location options are available to search and rent in the UK and abroad. I was particularly focused on the authentication, design and deployment.</p>
                <span>
                  <a className="me-3 text-hover-reverse" href="https://cest-sei-project-3.herokuapp.com/" target="_blank" rel="noreferrer noopener">
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                  <a className="ms-1 me-3 text-hover-reverse" href="https://github.com/crigrande/sei-project-3/tree/development" target="_blank" rel="noreferrer noopener">
                    <i className="fab fa-github"></i>
                  </a>
                </span>
                <ul className="tags mt-3">
                  <li><a className="tag cursor-default">JavaScript</a></li>
                  <li><a className="tag cursor-default">React</a></li>
                  <li><a className="tag cursor-default">HTML</a></li>
                  <li><a className="tag cursor-default">CSS</a></li>
                  <li><a className="tag cursor-default">SASS</a></li>
                  <li><a className="tag cursor-default">Bootstrap</a></li>
                  <li><a className="tag cursor-default">Node.js</a></li>
                  <li><a className="tag cursor-default">Express</a></li>
                  <li><a className="tag cursor-default">MongoDB</a></li>
                  <li><a className="tag cursor-default">Insomnia</a></li>
                  <li><a className="tag cursor-default">Deployment - Heroku</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card h-100">
              <img src="https://res.cloudinary.com/dbc3fejob/image/upload/v1630020859/Portfolio/Screenshot_2021-08-27_at_00.33.33_ytxdbo.png" className="card-img-top img-fluid img-thumbnail img-card" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">VIBES - Full-stack web application</h5>
                <p className="card-text">Full-Stack web application to find places such as pubs and restaurants. New users can register an account and share with the community their favourite spot.</p>
                <span>
                  <a className="me-3 text-hover-reverse" href="https://vibes-sei-project-04.herokuapp.com/" target="_blank" rel="noreferrer noopener">
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                  <a className="ms-1 me-3 text-hover-reverse" href="https://github.com/crigrande/sei-project-4" target="_blank" rel="noreferrer noopener">
                    <i className="fab fa-github"></i>
                  </a>
                </span>
                <ul className="tags mt-3">
                  <li><a className="tag cursor-default">JavaScript</a></li>
                  <li><a className="tag cursor-default">React</a></li>
                  <li><a className="tag cursor-default">HTML</a></li>
                  <li><a className="tag cursor-default">CSS</a></li>
                  <li><a className="tag cursor-default">SASS</a></li>
                  <li><a className="tag cursor-default">Bootstrap</a></li>
                  <li><a className="tag cursor-default">Cloudinary</a></li>
                  <li><a className="tag cursor-default">Python</a></li>
                  <li><a className="tag cursor-default">Django</a></li>
                  <li><a className="tag cursor-default">PostgreSQL</a></li>
                  <li><a className="tag cursor-default">Insomnia</a></li>
                  <li><a className="tag cursor-default">Deployment - Heroku</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-sm-12 col-md-3">
        <div className="card h-100 border-0 no-background">
          <div className="grid">
            <figure className="effect-hera">
              <img src="https://res.cloudinary.com/dbc3fejob/image/upload/v1630020859/Portfolio/Screenshot_2021-08-27_at_00.33.33_ytxdbo.png" className="card-img-top img-fluid img-thumbnail img-card" alt="..."/>
              <figcaption className="massive-effect">
                <h2>Connect  
                  <span>4</span>
                </h2>
                <p>
                  <a href="#"><i className="fas fa-external-link-alt"></i></a>
                  <a href="#"><i className="fab fa-github"></i></a>
                </p>
              </figcaption>			
            </figure>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  )
}
export default Home