import React from 'react'


const Nav = () => {


  return (
    <>
      <nav className="navbar navbar-light bg-pastel-yellow navbar-expand-md p-3 shadow">
        <div className="container-fluid"><a className="navbar-brand" href="#projects">
          <img className="img-fluid logo" src="https://res.cloudinary.com/dbc3fejob/image/upload/v1629998597/Portfolio/LAST%20VERSION/Junior_Full-stack_developer_-_logo_small_bigger_version_fuch34.png"/>
        </a><button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1"><span className="visually-hidden">Toggle navigation</span><span className="navbar-toggler-icon"></span></button>
        <div className="collapse navbar-collapse justify-content-end" id="navcol-1">
          <ul className="navbar-nav d-xl-flex">
            <li className="nav-item"><a className="nav-link text-prussian-blue text-hover fw-bolder nav-text" href="#about">About</a></li>
            <li className="nav-item"><a className="nav-link text-prussian-blue text-hover fw-bolder nav-text" href="#projects">Projects</a></li>
            <li className="nav-item"><a className="nav-link text-prussian-blue text-hover fw-bolder nav-text" href="#contact">Contact</a></li>
          </ul>
        </div>
        </div>
      </nav>
    </>
  )
}
export default Nav